// material-ui
import React from "react";
import { Stack, Grid, Typography, CardMedia } from "@mui/material";

// project imports
import LogoSection from "../LogoSection";
import { useDispatch, useSelector } from "store";
import { getPlayerBalance, getUsersByID } from "store/slices/user";

import useAuth from "hooks/useAuth";

const bucketUrl = process.env.REACT_APP_IMAGE_URL;
// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { playerBalance, singleUsers } = useSelector((state) => state.user);

  React.useEffect(() => {
    dispatch(getPlayerBalance(user));
    dispatch(getUsersByID(user));
  }, [dispatch, user]);

  return (
    <>
      <Grid
        container
        direction="row"
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0px !important",
        }}
      >
        <Stack
          direction="row"
          spacing={0}
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid sx={{ ml: 1, mt: 0 }}>
            <Stack direction="column" spacing={0.5}>
              <Stack
                direction="row"
                spacing={1}
                justifyContent="center"
                alignItems="center"
                sx={{
                  minWidth: 90,
                  height: 30,
                  pr: "8px",
                  pl: "8px",
                  borderRadius: "64px",
                  backgroundImage:
                    "linear-gradient(#2f6183, rgb(52 52 52)), linear-gradient(#2f6783, rgb(52 52 52)), linear-gradient(180deg, #5fe8ff -30%, #00c3ff 22%, #005a78 72%, #005399 112%)",
                }}
              >
                <CardMedia
                  component="img"
                  sx={{
                    borderRadius: "8px",
                    width: "20px",
                  }}
                  image={`${bucketUrl}diamond.webp`}
                />
                <Typography
                  variant="h4"
                  align="right"
                  type="number"
                  style={{ color: "#fff" }}
                >
                  {parseInt(singleUsers[0]?.wheelPoint)} เพชร
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Stack>
        <LogoSection />
        <Stack
          direction="row"
          spacing={0}
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid sx={{ mr: 1, mt: 0 }}>
            <Stack direction="column" spacing={0.5}>
              <Stack
                direction="row"
                spacing={1}
                justifyContent="center"
                alignItems="center"
                sx={{
                  minWidth: 90,
                  height: 30,
                  pr: "8px",
                  pl: "8px",
                  borderRadius: "64px",
                  backgroundImage:
                    "linear-gradient(#83782f, rgb(52 52 52)), linear-gradient(180deg, #ffe75f -30%, #ffd400 22%, #787500 72%, #997c00 112%)",
                }}
              >
                <CardMedia
                  component="img"
                  sx={{
                    borderRadius: "8px",
                    width: "20px",
                  }}
                  image={`${bucketUrl}coin2.webp`}
                />
                <Typography
                  variant="h4"
                  align="right"
                  type="number"
                  style={{ color: "#fff" }}
                >
                  {parseInt(playerBalance)} บาท
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Stack>
      </Grid>
    </>
  );
};

export default Header;
