// NotFoundRedirect.js
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "hooks/useAuth";

const NotFoundRedirect = () => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/dashboard", { replace: true });
    } else {
      navigate("/", { replace: true });
    }
  }, [isLoggedIn, navigate]);

  return null;
};

export default NotFoundRedirect;
