// contexts/JWTContext.js

import React, {
  createContext,
  useEffect,
  useReducer,
  useState,
  useContext,
} from "react";
import PropTypes from "prop-types";
import jwtDecode from "jwt-decode";

// Import the SessionTimeoutContext
import SessionTimeoutContext from "./SessionTimeoutContext";

// Reducer and actions
import { LOGIN, LOGOUT } from "store/actions";
import accountReducer from "store/accountReducer";

// Project imports
import Loader from "ui-component/Loader";
import axios from "utils/axios";

// Constants
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

const verifyToken = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  const decoded = jwtDecode(serviceToken);
  return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem("serviceToken", serviceToken);
    axios.defaults.headers.common.Authorization = `${serviceToken}`;
  } else {
    localStorage.removeItem("serviceToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

// Create the JWTContext
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);
  const [logoutTimer, setLogoutTimer] = useState(null);

  // Access the SessionTimeoutContext
  const { setIsSessionTimeout } = useContext(SessionTimeoutContext);

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = window.localStorage.getItem("serviceToken");
        if (serviceToken && verifyToken(serviceToken)) {
          setSession(serviceToken);

          // Set logout timer
          setTokenExpiryHandler(serviceToken);

          // Fetch user data
          const response = await axios.get("/api/account/me");
          const { user } = response.data;
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user,
            },
          });
        } else {
          dispatch({
            type: LOGOUT,
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT,
        });
      }
    };
    init();

    // Clean up the timer on unmount
    return () => {
      if (logoutTimer) clearTimeout(logoutTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setTokenExpiryHandler = (token) => {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    const timeLeft = decoded.exp - currentTime;

    // Clear any existing timers
    if (logoutTimer) clearTimeout(logoutTimer);

    // Set a new timer
    const timer = setTimeout(() => {
      handleTokenExpiry();
    }, timeLeft * 1000); // Convert to milliseconds

    setLogoutTimer(timer);
  };

  const handleTokenExpiry = () => {
    // Set session timeout to true to display the dialog
    setIsSessionTimeout(true);
  };

  const login = async (username, password) => {
    const response = await axios.post("/api/account/login", {
      username,
      password,
    });
    const { serviceToken, user } = response.data;
    setSession(serviceToken);

    // Set logout timer
    setTokenExpiryHandler(serviceToken);

    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user,
      },
    });
  };

  const register = async (
    username,
    password,
    confirmPassword,
    bank_name,
    book_number,
    book_name,
    inviterID,
    registerFrom
  ) => {
    // Registration logic (assuming you have an API endpoint for this)
    await axios.post("/api/account/register", {
      username,
      password,
      confirmPassword,
      bank_name,
      book_number,
      book_name,
      inviterID,
      registerFrom,
    });

    // Handle the response as needed

    // Automatically log in the user after registration
    await login(username, password);
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: LOGOUT });

    // Clear the timer
    if (logoutTimer) clearTimeout(logoutTimer);

    // Reset the session timeout state
    setIsSessionTimeout(false);

    // Clear local storage and redirect
    window.localStorage.clear();
    window.top.location.href = "https://24xbet.co/";
  };

  const resetPassword = (email) => {
    // Implement your password reset logic here
    console.log(email);
  };

  const updateProfile = () => {
    // Implement your profile update logic here
  };

  if (!state.isInitialized) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider
      value={{
        ...state,
        login,
        logout,
        register,
        resetPassword,
        updateProfile,
      }}
    >
      {children}
    </JWTContext.Provider>
  );
};

JWTProvider.propTypes = {
  children: PropTypes.node,
};

export default JWTContext;
