export const JWT_API = {
  secret:
    "c356f06ba94b2a8eac9107bae73f1fb6a5bfb66d9b5b8ea6297fb75f041aad510188366037e429cd454739d1f06dd3bccb0fc6bec84a106993465b9810c1b946",
  timeout: "2 hours",
  //timeout: "5 minutes",
};

export const FIREBASE_API = {
  apiKey: "AIzaSyBernKzdSojh_vWXBHt0aRhf5SC9VLChbM",
  authDomain: "berry-material-react.firebaseapp.com",
  projectId: "berry-material-react",
  storageBucket: "berry-material-react.appspot.com",
  messagingSenderId: "901111229354",
  appId: "1:901111229354:web:a5ae5aa95486297d69d9d3",
  measurementId: "G-MGJHSL8XW3",
};

export const AUTH0_API = {
  client_id: "7T4IlWis4DKHSbG8JAye4Ipk0rvXkH9V",
  domain: "dev-w0-vxep3.us.auth0.com",
};

export const AWS_API = {
  poolId: "us-east-1_AOfOTXLvD",
  appClientId: "3eau2osduslvb7vks3vsh9t7b0",
};

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = "";

export const DASHBOARD_PATH = "/dashboard/analytics";

export const HOME_PATH = "/dashboard/home";
//export const HOME_PATH = "/dashboard/home";

const config = {
  fontFamily: `Roboto, sans-serif`,
  borderRadius: 8,
  outlinedFilled: true,
  navType: "light", // light, dark
  presetColor: "default", // default, theme1, theme2, theme3, theme4, theme5, theme6
  locale: "en", // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
  rtlLayout: false,
  container: false,
};

export default config;
