// MainRoutes.js
import { lazy } from "react";
// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import GuestGuard from "utils/route-guard/GuestGuard";
import NotFoundRedirect from "./NotFoundRedirect"; // Update the import path

// Loadable components
const DashboardAnalytics = Loadable(
  lazy(() => import("views/dashboard/Analytics"))
);
const DashboardPlay = Loadable(lazy(() => import("views/dashboard/Play")));
const DashboardHome = Loadable(lazy(() => import("views/dashboard/Home")));

// ==============================|| MAIN ROUTES ||============================== //

const MainRoutes = [
  // Public Route for DashboardHome with GuestGuard
  {
    path: "/",
    element: (
      <GuestGuard>
        <MainLayout />
      </GuestGuard>
    ),
    children: [
      {
        path: "/",
        element: <DashboardHome />,
      },
    ],
  },
  // Protected Routes
  {
    path: "/",
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "dashboard",
        element: <DashboardAnalytics />,
      },
      {
        path: "lobby",
        element: <DashboardPlay />,
      },
    ],
  },
  // Catch-all route
  {
    path: "*",
    element: <NotFoundRedirect />,
  },
];

export default MainRoutes;
