import React, { createContext, useState } from "react";

const SessionTimeoutContext = createContext();

export const SessionTimeoutProvider = ({ children }) => {
  const [isSessionTimeout, setIsSessionTimeout] = useState(false);

  return (
    <SessionTimeoutContext.Provider
      value={{ isSessionTimeout, setIsSessionTimeout }}
    >
      {children}
    </SessionTimeoutContext.Provider>
  );
};

export default SessionTimeoutContext;
