// material-ui
import React from "react";
import { useDispatch, useSelector } from "store";
//import { useTheme } from "@mui/material/styles";

import { getWebsiteSetting } from "store/slices/user";
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = (propsData) => {
  //const theme = useTheme();
  const dispatch = useDispatch();
  const { websiteSetting } = useSelector((state) => state.user);
  const [previewImage, setPreviewImage] = React.useState();

  const [height, setHeight] = React.useState();

  React.useEffect(() => {
    setHeight(propsData.height);
  }, [propsData]);

  React.useEffect(() => {
    if (websiteSetting?.length > 0) {
      setPreviewImage("https://api.24xbet.co" + websiteSetting[0]?.logoUrl);
    }
  }, [websiteSetting]);

  React.useEffect(() => {
    dispatch(getWebsiteSetting());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      {previewImage && (
        <img
          src={previewImage}
          alt="web"
          width={"auto"}
          style={{ maxWidth: "100%", maxHeight: `${height}` }}
        />
      )}
    </>
  );
};

export default Logo;
