import React, { useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import SessionTimeoutContext from "contexts/SessionTimeoutContext";
import useAuth from "hooks/useAuth";
import { useNavigate } from "react-router-dom";

const SessionTimeoutDialog = () => {
  const { isSessionTimeout, setIsSessionTimeout } = useContext(
    SessionTimeoutContext
  );
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleClose = () => {
    setIsSessionTimeout(false);
    logout();
    navigate("/", { replace: true });
  };

  return (
    <Dialog
      open={isSessionTimeout}
      onClose={handleClose}
      aria-labelledby="session-timeout-dialog-title"
      aria-describedby="session-timeout-dialog-description"
      style={{
        backgroundColor: "#00000099",
      }}
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: "#222",
        },
      }}
    >
      <DialogTitle id="session-timeout-dialog-title">
        <Typography variant="h4">Session Expired - เซสซั่นหมดอายุ</Typography>
      </DialogTitle>
      <DialogContent id="session-timeout-dialog-description">
        <Typography>หมดเวลาเข้าสู่ระบบ กรุณา Login ใหม่อีกครั้ง.</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="contained"
          color="warning"
          size="large"
        >
          ล๊อคอิน
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SessionTimeoutDialog;
