import React, { createContext, useEffect } from "react";
import socket from "./socket"; // ใช้ socket ที่ประกาศครั้งเดียว

export const SocketContext = createContext();

const SocketProvider = ({ children }) => {
  useEffect(() => {
    // Log การเชื่อมต่อ
    socket.on("connect", () => {
      console.log("Connected to Socket.IO server:", socket.id);
    });

    socket.on("disconnect", () => {
      console.log("Disconnected from Socket.IO server");
    });

    // Cleanup การเชื่อมต่อเมื่อ component ถูกทำลาย
    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export default SocketProvider;
